import React from 'react';
import colors from 'Utils/theme';

const Close = ({ fill = colors.inHome.darkGrey }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill={fill}>
      <path d="M11 9.586L20.192.393l1.415 1.415L12.414 11l9.193 9.192-1.415 1.415L11 12.414l-9.192 9.193-1.415-1.415L9.586 11 .393 1.808 1.808.393 11 9.586z" />
    </svg>
  );
};

export default Close;
