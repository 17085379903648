import { useEffect } from 'react';

const useKeyDownInput = ({ keyCode = null, key = null, func = null, test = false }) =>
  useEffect(() => {
    const pressedKey = (e) => {
      if (test) {
        console.log('event.keyCode', e.keyCode); // eslint-disable-line no-console
        console.log('event.key', e.key); // eslint-disable-line no-console
        console.log('event.location', e.location); // eslint-disable-line no-console
        console.log('event.code', e.code); // eslint-disable-line no-console
      }

      if (e.keyCode === keyCode || e.key === key) {
        return func();
      }
    };

    document.addEventListener('keydown', pressedKey, false);
    return () => document.removeEventListener('keydown', pressedKey, false);
  }, []);

export default useKeyDownInput;

/*
  Currently this is only for single keys. Check out 
  https://stackoverflow.com/questions/5203407/how-to-detect-if-multiple-keys-are-pressed-at-once-using-javascript
  to expand on this functionality.

  References:
  https://keycode.info/ - Thanks Wes Bos!
*/
