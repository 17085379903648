import styled from '@emotion/styled';
import { animated } from 'react-spring';
import { transparentize } from 'polished';

import { minTablet } from 'Utils/variables';
import colors from 'Utils/theme';

export const StyledModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 666;
  overflow: scroll;
`;

export const StyledModal = styled(animated.div)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 24px;

  /* Positioning properties: Mobile */
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
  right: 0;
  bottom: 0;
  z-index: 667;

  /* Styling properties */
  width: 95%;
  overflow-x: hidden;

  /* transform: translate(-50%, -50%); */
  will-change: transform;
  margin-bottom: 20px;

  @media (min-width: ${(props) => (props.maxwidth ? props.maxwidth + 40 + 'px' : minTablet)}) {
    position: absolute;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    top: 50px;
    height: auto;
    max-width: ${(props) => (props.maxwidth ? props.maxwidth + 'px' : '480px')};
    min-height: 380px;
    right: auto;
    bottom: auto;
    margin-bottom: 50px;
  }
`;

/**
 * The <StyledBackground/>'s main functionality is for obscuring the main page content
 * and onClick, closing the modal outside of the modal context.
 */
export const StyledBackground = styled('div')`
  /* Positioning properties: Take up the entire viewport with overlay under Modal */
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 666;

  /* Styling properties */
  background-color: rgba(244, 244, 244, 0.9);
`;

export const DefaultTrigger = styled('span')`
  &:before {
    display: flex;
    content: 'Open Modal';
  }
`;

// TODO: Add content that explains how to use the Modal. Extra. Make it look like code. That would be nice.
export const DefaultContent = styled('div')`
  &:before {
    display: flex;
    content: 'Add Modal Content Here';
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 10;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: ${transparentize(0.4, colors.white)};

  margin-left: auto;

  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
`;
