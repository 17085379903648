import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useSpring } from 'react-spring';

import useMedia from 'Hooks/useMedia';
import useKeyDownInput from 'Hooks/useKeyDownInput';

import Close from 'Icons/Close';

import { StyledModal, StyledBackground, DefaultTrigger, DefaultContent, CloseButton } from './styles';
import colors from 'Utils/theme';

const Modal = ({
  trigger: TriggerComponent = DefaultTrigger,
  content: ContentComponent = DefaultContent,
  displayCloseButton = true,
  displayTriggerComponent = true,
  openModalCall = false,
  maxWidth = null,
  onClose = () => {},
  bgColor = colors.white,
  color = colors.walmart.blue,
  bgCloseDisabled = false,
  ...props
}) => {
  const closeRef = useRef(null);

  const isMobile = useMedia('(max-width: 839px)');
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    !mounted && setMounted(true);
  }, []);

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
    onClose();
    if (typeof document !== 'undefined') {
      document.documentElement.style.overflow = '';
      document.body.style.overflow = '';
    }
  };

  const [overlayProps, setOverlay] = useSpring(() => ({
    opacity: 0,
    pointerEvents: 'none',
    config: { mass: 0.6, friction: 16 },
  }));

  const [listProps, setList] = useSpring(() => ({
    opacity: 0,
    pointerEvents: isMobile ? 'none' : 'all',
    config: { mass: 0.6, friction: 16 },
  }));

  useEffect(() => {
    setOverlay({
      opacity: showModal ? 1 : 0,
      pointerEvents: showModal ? 'all' : 'none',
    });

    setList({
      opacity: showModal ? 1 : 0,
      pointerEvents: showModal ? 'all' : 'none',
    });
  }, [showModal]);

  useKeyDownInput({ key: 'Escape', func: closeModal });

  const Portal = ({ children }) => {
    const element = document.createElement('div');
    const html = document.documentElement;
    const body = document.body;

    element.id = 'portal';
    element.style.cssText = `
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 666;
      overflow: scroll;
    `;

    useEffect(() => {
      if (showModal) {
        html.style.overflow = 'hidden';
      } else {
        html.style.overflow = '';
      }

      body.appendChild(element);
      return () => {
        body.removeChild(element);
        html.style.overflow = '';
      };
    }, []);

    return createPortal(children, element);
  };

  if (openModalCall) {
    showModal !== true && setShowModal(true);
  }

  if (!mounted) {
    return null;
  }

  return (
    <>
      {displayTriggerComponent && <TriggerComponent openModal={() => setShowModal(true)} />}
      {showModal && (
        <Portal>
          <StyledModal maxwidth={maxWidth} style={{ ...listProps, backgroundColor: bgColor, color: color }}>
            {displayCloseButton && (
              <CloseButton ref={closeRef} tabIndex="0" aria-label="Close Modal" onClick={closeModal}>
                <Close fill={colors.walmart.blue} />
              </CloseButton>
            )}
            <ContentComponent closeModal={closeModal} {...props} />
          </StyledModal>
          <StyledBackground style={{ ...overlayProps }} onClick={!bgCloseDisabled ? closeModal : null} />
        </Portal>
      )}
    </>
  );
};

export default Modal;
